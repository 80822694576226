import Shell from 'components/Shell/Shell';
import SectionModules from 'containers/Sections/SectionModules';
import { Contentful, getMasterControl } from '@viome/vnxt-nucleus';
import Schema from 'utils/schema/Home';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { contentfulOptions as options } from 'utils/constants';

const Page = (props) => {
    const { preview, masterControl, page } = props,
        sections = page?.content?.sections.items || [];

    return (
        <Shell
            masterControl={masterControl}
            page={page}
            preview={preview}
            className="viome3 viome"
        >
            <SectionModules modules={sections} />
            <Schema />
        </Shell>
    );
};

export default Page;

export async function getStaticProps({ preview = false }) {
    const pageData = Promise.all([
            getMasterControl.get(options),
            Contentful(
                'page',
                {
                    include: 10,
                    'fields.slug[in]': 'habit-index',
                },
                false,
                options,
            ),
        ]),
        props = await pageData.then(async ([masterControl, page]) => ({
            preview,
            masterControl,
            page,
        }));

    return { props, revalidate: 240 };
}
